.experience {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  /* height: 30vh; */
  margin-bottom: 8rem;
  /* darkmode */
  color: var(--black);
  flex-wrap: wrap;
  /* background-color: yellowgreen; */
}
.achievement{
    display: flex;
    flex-direction: column;    
    align-items: center;
    transition: 0.3s ease-in-out;
    cursor: pointer;

}

  
  .achievement:hover {
    transform: translateX(0.5rem) translateY(-0.5rem);
  }
  
  .achievement:hover .image img {
    transform: scale(115%);
  }
.circle {
    width: 6rem;
    height: 6rem;
    background: white;
    border-radius: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
}
.circle::before {
    border-radius: 10%;
    content: '';
    background-image: linear-gradient(to bottom, #87e6fb 0%, #ffc05c 100%);
    top: -8px;
    left: -8px;
    bottom: -8px;
    right: -8px;
    position: absolute;
    z-index:-1;
    box-shadow: var(--boxShadow);
}

.achievement span:nth-of-type(2){
    color: var(--orange);
}


@media screen and (max-width: 480px) {
    .experience{
        transform: scale(0.7);
    }
}