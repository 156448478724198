.footer {
  display: flex;
  align-items: center;
  margin: -0.5rem -3.5rem;
  position: relative;
  margin-top: -7rem;
}
.f-content {
  position: absolute;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 7rem;
  gap: 1rem;
  color:white;
  font-weight: 600;
  padding-left: 5%;
  padding-top: 7%;
  /* background-color: aqua; */
}
.f-icons {
  display: flex;
  gap: 2rem;
}

@media screen and (max-width: 480px){
  .f-content{
    transform: scale(.7);
  }
  .footer{
    height: 30rem;
    margin-top: 1rem;
  }
  
}
